import * as React from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Color, addAlpha } from '../../util/Color'
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { Divider, Typography } from "@mui/material"
import Text from "../../components/feature/Recruitment/Text"
import { Button } from "@mui/material"

const Recruitment = () => {

  const submit = () => {
    navigate('/contact')
  }

  return (
    <Layout>
      <Seo title="Recruitment" />
      <Img>
        <StaticImage src="../../components/feature/Recruitment/back.png" alt="top"/>
      </Img>

      <Body>
        <Margin margin="72"/>
        <STypography variant="h5">フロントエンドエンジニア</STypography>
        <SDivider />
        <Text label="業務内容" text="Blockchain技術を利用したシステム開発業務" />
        <Text label="応募条件" text="社会人経験" />
        <Text label="求めるスキル" text="ブロックチェーン業界および開発経験者" />
        <Text label="雇用形態" text="正社員" />
        <ButtonWrapper>
          <SButton variant="outlined" onClick={submit}>応募</SButton>
        </ButtonWrapper>
      </Body>
    </Layout>
  )
}

export default Recruitment

const Body = styled('div')({
  margin: '0px 15vw',
  width: '70vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start'
})

const Margin = styled('div')(({margin}) => ({
  height: margin + 'px',
  width: '1px',
}))

const Img = styled('div')({
  width: '100%'
})

const SDivider = styled(Divider)({
  height: '1px',
  width: '100%',
  background: Color.orange,
  border: 'none',
  margin: '32px 0px 0px'
})
const STypography = styled(Typography)({
  margin: '8px',
  fontWeight: 700,
  color: Color.black,
  fontFamily: 'Noto Sans JP'
})

const SButton = styled(Button)({
  borderRadius: '200px',
  color: Color.black,
  borderColor: Color.orange,
  ":hover": {
    borderColor: Color.orange,
    background: addAlpha(Color.orange, 0.1),
  },
  ":active": {
    borderColor: Color.orange
  }
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  marginTop: '38px',
  justifyContent: 'end',
  '> button' : {
    fontFamily: 'Noto Sans JP',
    padding: '6px 40px'
  }
})
